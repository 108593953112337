import { Component } from 'react';
import debounce from 'lodash.debounce';
import QuantityInput from 'components/atoms/QuantityInput';
import UpdateCartMutation from 'components/atoms/UpdateCartMutation';

class QuantityControl extends Component {
  state = {
    prevLoading: this.props.loading,
    prevQuantity: this.props.cartItem.quantity,
    quantity: this.props.cartItem.quantity,
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (state.prevLoading !== props.loading) {
      newState.prevLoading = props.loading;
    }

    if (state.prevQuantity !== props.cartItem.quantity || (state.prevLoading && !props.loading)) {
      Object.assign(newState, {
        prevQuantity: props.cartItem.quantity,
        quantity: props.cartItem.quantity,
      });
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  saveQuantity = debounce(() => {
    this.props.onStartLoading();
    this.props.runMutation({
      variables: {
        itemId: this.props.cartItem.id,
        quantity: +this.state.quantity,
        action: 'UPDATE',
      },
    });
  }, 1000);

  handleChange = (quantity) => {
    this.setState(
      {
        quantity,
      },
      () => {
        this.saveQuantity();
      },
    );
  };

  render() {
    return (
      <QuantityInput
        disabled={this.props.disabled}
        value={this.state.quantity}
        onChange={this.handleChange}
      />
    );
  }
}

export default (props) => (
  <UpdateCartMutation
    onError={() => {
      props.onFinishLoading();
      window.alert('Произошла ошибка. Попробуйте позднее.');
    }}
    onCompleted={() => {
      props.onFinishLoading();
    }}
  >
    {(runMutation, result) => <QuantityControl {...props} runMutation={runMutation} {...result} />}
  </UpdateCartMutation>
);
