import { Component } from 'react';
import styled, { css } from 'styled-components';
import constants from 'constants/constants';
import Link from 'components/atoms/Link';
import Alert from 'components/atoms/Alert';
import DeleteButton from './DeleteButton';
import QuantityControl from './QuantityControl';

const PUBLIC_BUCKET_URL = constants.PUBLIC_BUCKET_URL;

const Wrapper = styled.div``;

const Row = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  margin-bottom: 0.5em;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    align-items: center;
    flex-direction: row;
  }
`;

const Col = styled.div`
  flex: none;
  padding: 0.6em;
`;

const ItemInfoCol = styled(Col)`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    align-items: center;
    flex-direction: row;
    order: 1;
    width: 50%;
    flex: none;
  }
`;

const QuantityCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 2;
    flex: none;
    width: ${({ noDeleteCol }) =>
      noDeleteCol ? css`calc(50% / 3)` : css`calc((50% - 2.2em) / 3)`};
  }
`;

const PriceCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 3;
    flex: none;
    width: ${({ noDeleteCol }) =>
      noDeleteCol ? css`calc(50% / 3)` : css`calc((50% - 2.2em) / 3)`};
  }
`;

const TotalCol = styled(Col)`
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 4;
    flex: none;
    width: ${({ noDeleteCol }) =>
      noDeleteCol ? css`calc(50% / 3)` : css`calc((50% - 2.2em) / 3)`};
  }
`;

const DeleteCol = styled(Col)`
  position: absolute;
  top: 0;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    order: 5;
    position: static;
  }
`;

const HeadingRow = styled(Row)`
  font-weight: 600;
  display: none;
  border: none;

  ${ItemInfoCol}, ${PriceCol}, ${QuantityCol}, ${TotalCol} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${ItemInfoCol} {
    padding-left: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: flex;
    align-items: flex-end;
  }
`;

const Img = styled.div`
  width: 4em;
  height: 4em;
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  flex: none;
  margin-right: 1em;
  ${({ src }) =>
    src
      ? css`
          background: url('${PUBLIC_BUCKET_URL + src}') center center no-repeat;
        `
      : css`
          display: none;
        `};
  background-size: cover;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
  }
`;

const Name = styled.div`
  flex: 1 1 auto;
  margin-top: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin-top: 0;
  }
`;

const Total = styled.div`
  font-weight: 600;
`;

const NoPrice = styled.span.attrs({
  children: 'Не указана',
})`
  font-style: italic;
`;

class Table extends Component {
  state = {
    inProgress: false,
  };

  handleLoadingStart = () => {
    this.setState({
      inProgress: true,
    });
  };

  handleLoadingFinish = () => {
    this.setState({
      inProgress: false,
    });
  };

  render() {
    const { cartItems, readOnly } = this.props;
    return (
      <>
        <Wrapper>
          <HeadingRow>
            <ItemInfoCol>Товар</ItemInfoCol>
            <QuantityCol noDeleteCol={readOnly}>Количество</QuantityCol>
            <PriceCol noDeleteCol={readOnly}>Цена</PriceCol>
            <TotalCol noDeleteCol={readOnly}>Сумма</TotalCol>
          </HeadingRow>
          {cartItems.map((cartItem) => (
            <Row key={cartItem.id}>
              <ItemInfoCol>
                <Img
                  src={
                    cartItem.item && cartItem.item.images[0] && cartItem.item.images[0].value.thumb
                  }
                />
                <Name>
                  {(cartItem.item && (
                    <Link
                      href={`/catalog/${cartItem.item.category.translit}/${cartItem.item.id}`}
                      target="_blank"
                    >
                      {cartItem.item.name}
                    </Link>
                  )) ||
                    cartItem.name}
                </Name>
              </ItemInfoCol>
              <PriceCol noDeleteCol={readOnly}>
                {cartItem.price ? `${cartItem.price.toFixed(2)} ₽` : <NoPrice />}
              </PriceCol>
              <QuantityCol noDeleteCol={readOnly}>
                {readOnly ? (
                  cartItem.quantity
                ) : (
                  <QuantityControl
                    cartItem={cartItem}
                    disabled={this.state.inProgress}
                    onStartLoading={this.handleLoadingStart}
                    onFinishLoading={this.handleLoadingFinish}
                  />
                )}
              </QuantityCol>
              <TotalCol noDeleteCol={readOnly}>
                <Total>
                  {cartItem.price && `${(cartItem.price * cartItem.quantity).toFixed(2)} ₽`}
                </Total>
              </TotalCol>
              {!readOnly && (
                <DeleteCol>
                  <DeleteButton
                    cartItem={cartItem}
                    disabled={this.state.inProgress}
                    onStartLoading={this.handleLoadingStart}
                    onFinishLoading={this.handleLoadingFinish}
                  />
                </DeleteCol>
              )}
            </Row>
          ))}
        </Wrapper>
        <Alert narrow>
          <strong>Внимание!</strong> Если в корзине имеются товары без указанной цены, то общая
          сумма считается без учёта таких товаров.
        </Alert>
      </>
    );
  }
}

export default Table;
