import { Component } from 'react';
import styled from 'styled-components';
import DeleteIcon from 'react-icons/lib/md/close';
import Button from 'components/atoms/Button';
import UpdateCartMutation from 'components/atoms/UpdateCartMutation';
import Modal from 'components/atoms/Modal';

const Wrapper = styled(Button)`
  margin: -0.6em;
  padding: 0.6em;
`;

const DeleteIconWrapper = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: #cb2929;
  color: #ffffff;
  position: relative;

  &:active,
  &:focus {
    background-color: #841c1c;
  }
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ApproveDeleteButton = ({ onStartLoading, cartItem, onSuccess, onError, ...rest }) => (
  <UpdateCartMutation
    variables={{ itemId: cartItem.id, action: 'DELETE' }}
    onError={onError}
    onCompleted={onSuccess}
  >
    {(runMutation, { loading }) => (
      <Button
        primary
        inline
        onClick={() => {
          onStartLoading();
          runMutation();
        }}
        {...rest}
      >
        {loading ? 'Загрузка...' : 'Удалить'}
      </Button>
    )}
  </UpdateCartMutation>
);

class DeleteButton extends Component {
  state = {
    isModalShown: false,
  };

  componentWillUnmount() {
    this.props.onFinishLoading();
  }

  handleDeleteButtonClick = () => {
    this.setState({
      isModalShown: true,
    });
  };

  handleModalHide = () => {
    this.setState({
      isModalShown: false,
    });
  };

  handleSuccess = () => {
    this.props.onFinishLoading();
    this.setState({
      isModalShown: false,
    });
  };

  handleError = () => {
    this.props.onFinishLoading();
    window.alert('Произошла ошибка. Попробуйте позднее.');
  };

  render() {
    const { cartItem, disabled, onStartLoading } = this.props;

    return (
      <>
        <Modal
          show={this.state.isModalShown}
          title="Удаление из корзины"
          actions={[
            <ApproveDeleteButton
              onStartLoading={onStartLoading}
              disabled={disabled}
              cartItem={cartItem}
              onSuccess={this.handleSuccess}
              onError={this.handleError}
            />,
            <Button neutral inline onClick={this.handleModalHide}>
              Отмена
            </Button>,
          ]}
          onHide={this.handleModalHide}
        >
          <p>
            Вы действительно хотите удалить &quot;
            {(cartItem.item && cartItem.item.name) || cartItem.name}
            &quot; из корзины?
          </p>
        </Modal>
        <Wrapper disabled={disabled} onClick={this.handleDeleteButtonClick}>
          <DeleteIconWrapper>
            <StyledDeleteIcon />
          </DeleteIconWrapper>
        </Wrapper>
      </>
    );
  }
}

export default DeleteButton;
